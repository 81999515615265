<template>
  <div :class="$style.tags">
    <el-tag
      v-for="(opt, index) in value"
      :key="opt + String(index)"
      :class="$style.tag"
      closable
      :disable-transitions="false"
      @close="handleClose(opt)"
      @click="$emit('click', opt)"
    >
      {{ opt }}
    </el-tag>
    <el-input
      :class="$style.inputNewTag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="addInput"
      size="mini"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button
      v-else
      :class="$style.buttonNewTag"
      size="small"
      @click="showInput"
    >
      + Новый вариант
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dynamicTags: ['Tag 1', 'Tag 2', 'Tag 3'],
      inputVisible: false,
      inputValue: ''
    }
  },
  methods: {
    handleClose(tag) {
      const tags = [...this.value]
      tags.splice(tags.indexOf(tag), 1)
      this.$emit('input', tags)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.addInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      const tags = [...this.value]
      if (this.inputValue) {
        tags.push(this.inputValue)
        this.$emit('input', tags)
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>

<style lang="scss" module>
.tags {
  margin-bottom: 1rem;
  .label {
    font-weight: bold;
    color: #606266;
    font-size: 0.875rem;
    margin-right: 2rem;
  }
  .tag + .tag {
    margin-left: 0.625rem;
  }
  .buttonNewTag {
    margin-left: 0.625rem;
    height: 2rem;
    line-height: 1.875rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .inputNewTag {
    width: 5.625rem;
    margin-left: 0.625rem;
    vertical-align: bottom;
  }
}
</style>