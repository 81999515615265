<template>
  <div :class="$style.main">
    <div :class="$style.wrapper">
      <el-button
        :class="$style.button"
        type="primary"
        @click="submitForm('form')"
      >
        Сохранить
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="120px"
    >
      <div :class="$style.blockTitle">Баннер</div>
      <el-form-item label="Заголовок" prop="titleSection.title">
        <el-input
          v-model="form.titleSection.title"
          data-test-id="titleSection_title"
          type="textarea"
        />
      </el-form-item>

      <el-form-item
        label="Параметры заголовка"
        prop="titleSection.titleOptions"
      >
        <Tags v-model="form.titleSection.titleOptions" />
      </el-form-item>
      <el-form-item label="Подзаголовок" prop="titleSection.subTitle">
        <el-input
          v-model="form.titleSection.subTitle"
          data-test-id="titleSection_subTitle"
          type="textarea"
        />
      </el-form-item>

      <div :class="$style.blockTitle">Блок Решаем проблемы</div>
      <el-form-item label="Заголовок" prop="solutionSection.title">
        <el-input
          v-model="form.solutionSection.title"
          type="textarea"
          data-test-id="solutionSection_title"
        />
      </el-form-item>
      <el-form-item label="Пункты" prop="solutionSection.content">
        <el-button
          style="margin-bottom: 1rem"
          type="primary"
          icon="el-icon-plus"
          :disabled="form.solutionSection.content.length >= 5"
          @click="addSolutionSectionContentItem"
        >
          Добавить
        </el-button>
        <div
          v-for="(item, index) in form.solutionSection.content"
          :key="index"
          :class="$style.solutionContent"
        >
          <el-select v-model="item.iconName" placeholder="Select">
            <el-option
              v-for="(icon, index) in solutionSectionIcons"
              :key="icon"
              :data-test-id="'solutionSection_option' + index"
              :label="icon"
              :value="icon"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="item.text"
            type="textarea"
            size="mini"
            data-test-id="solutionSection_title"
          />
          <el-button
            icon="el-icon-delete-solid"
            @click="removeSolutionSectionContentItem(index)"
          />
        </div>
      </el-form-item>

      <div :class="$style.blockTitle">Блок Настройте свой бизнес</div>
      <el-form-item label="Заголовок" prop="businessSetupSection.title">
        <el-input
          v-model="form.businessSetupSection.title"
          type="textarea"
          data-test-id="titleSection_title"
        />
      </el-form-item>
      <el-form-item
        label="Параметры заголовка"
        prop="businessSetupSection.titleOptions"
      >
        <Tags v-model="form.businessSetupSection.titleOptions" />
      </el-form-item>
      <el-form-item label="Подзаголовок" prop="businessSetupSection.subTitle">
        <el-input
          v-model="form.businessSetupSection.subTitle"
          type="textarea"
          data-test-id="titleSection_subTitle"
        />
      </el-form-item>

      <div :class="$style.blockTitle">Карточки</div>
      <div
        :class="$style.cards"
        v-if="form.businessSetupSection?.cards?.length > 1"
      >
        <div :class="$style.card">
          <el-form-item
            label="Заголовок"
            prop="businessSetupSection.cards[0].title"
          >
            <el-input
              v-model="form.businessSetupSection.cards[0].title"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Подзаголовок"
            prop="businessSetupSection.cards[0].text"
          >
            <el-input
              v-model="form.businessSetupSection.cards[0].text"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Текст кнопки"
            prop="businessSetupSection.cards[0].buttonText"
          >
            <el-input
              v-model="form.businessSetupSection.cards[0].buttonText"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Отн. Ссылка "
            prop="businessSetupSection.cards[0].buttonLink"
          >
            <el-input
              v-model="form.businessSetupSection.cards[0].buttonLink"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Текст количества"
            prop="businessSetupSection.cards[0].counterText"
          >
            <el-input
              v-model="form.businessSetupSection.cards[0].counterText"
              type="textarea"
              data-test-id="titleSection_title"
            />
          </el-form-item>
        </div>
        <div :class="$style.card">
          <el-form-item
            label="Заголовок"
            prop="businessSetupSection.cards[1].title"
          >
            <el-input
              v-model="form.businessSetupSection.cards[1].title"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Подзаголовок"
            prop="businessSetupSection.cards[1].text"
          >
            <el-input
              v-model="form.businessSetupSection.cards[1].text"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Текст кнопки"
            prop="businessSetupSection.cards[1].buttonText"
          >
            <el-input
              v-model="form.businessSetupSection.cards[1].buttonText"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Отн. Ссылка "
            prop="businessSetupSection.cards[1].buttonLink"
          >
            <el-input
              v-model="form.businessSetupSection.cards[1].buttonLink"
              data-test-id="titleSection_title"
            />
          </el-form-item>
          <el-form-item
            label="Текст кол-ва {count}"
            prop="businessSetupSection.cards[1].counterText"
          >
            <span slot="label">
              Текст кол-ва {count}
              <el-tooltip :content="tooltipCardContent" placement="top-end">
                <i class="el-input__icon el-icon-question" />
              </el-tooltip>
            </span>
            <el-input
              v-model="form.businessSetupSection.cards[1].counterText"
              type="textarea"
              data-test-id="titleSection_title"
            >
            </el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Tags from '@/components/atoms/Tags.vue'

export default {
  components: {
    Tags,
  },
  data() {
    return {
      form: {
        titleSection: {},
        solutionSection: {
          content: [],
        },
        businessSetupSection: {
          title: '',
          titleOptions: [],
          cards: [],
        },
      },
      cardValue: {
        title: '',
        text: '',
        buttonText: '',
        buttonLink: '',
        counterText: '',
      },
      solutionSectionIcons: [
        'number1',
        'number2',
        'number3',
        'number4',
        'number5',
      ],
      pages: [],
      total: 0,
      dealerCount: 0,
      rules: {
        titleSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          titleOptions: {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
          subTitle: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        },
        solutionSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          content: {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        },
        businessSetupSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          titleOptions: {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
          subTitle: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          cards: [
            {
              title: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              text: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              buttonText: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              buttonLink: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              counterText: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            },
            {
              title: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              text: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              buttonText: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              buttonLink: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              counterText: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            },
          ],
        },
      },
    }
  },
  computed: {
    tooltipCardContent() {
      if (!this.form?.businessSetupSection?.cards[1]?.counterText) {
        return String(this.dealerCount)
      }
      return this.form.businessSetupSection.cards[1].counterText.replace(
        '{count}',
        this.dealerCount,
      )
    },
  },
  created() {
    this.getPage('')
    this.getDealerCount()
  },
  methods: {
    async getPage(slug) {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(slug, 'sellers')
      loading.close()
      if (error) return

      this.form = { ...this.form, ...value.content }

      if (this.form?.businessSetupSection?.cards?.length < 2) {
        this.form.businessSetupSection.cards = [
          {
            ...(value.content?.businessSetupSection?.cards[0] ??
              this.cardValue),
          },
          {
            ...(value.content?.businessSetupSection?.cards[1] ??
              this.cardValue),
          },
        ]
      }
      if (!this.form?.solutionSection?.content) {
        this.form.solutionSection.content = []
      }
      if (!this.form?.titleSection?.titleOptions) {
        this.form.titleSection.titleOptions = []
      }
      if (!this.form?.solutionSection?.titleOptions) {
        this.form.solutionSection.titleOptions = []
      }
      if (!this.form?.businessSetupSection?.titleOptions) {
        this.form.businessSetupSection.titleOptions = []
      }
    },
    async getDealerCount() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.DealersActions.getDealersCount()
      loading.close()
      if (error) return

      this.dealerCount = value
    },
    addSolutionSectionContentItem() {
      this.form.solutionSection.content.push({ iconName: '1', text: '' })
    },
    removeSolutionSectionContentItem(index) {
      this.form.solutionSection.content.splice(index, 1)
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const result = await delivery.ContentServiceCore.PagesActions.update(
            '',
            'sellers',
            this.form,
          )
          if (result.error) {
            alert('Ошибка обновлена страницы: ' + result.error)
          } else {
            alert('Старница успешно обновлена')
          }
          loading.close()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.main {
  padding: 1rem;
  .blockTitle {
    @include blockTitle;
  }
  .wrapper {
    @include stickyWrapper;
  }
  .solutionContent {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .cards {
    display: flex;
    .card {
      width: 100%;
    }
  }
}
</style>
